.homeTopContainer {
  display: flex;
  width: 80%;
  padding: 0 10%;
  padding-top: 150px;
}

.homeCardContainer {
  width: auto;
  display: flex;
  align-items: center;
  padding-left: 150px;
}

.homeASACard {
  max-width: 1000px;
  max-height: 350px;
  width: auto;
  height: auto;
  background-clip: content-box;
}

.homeAboutUsContainer {
  margin-top: 350px;
  display: flex;
  flex-direction: column;
}

.homeAboutUsWrapper {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeAboutUs1P {
  color: #c2b280;
  margin: 0;
}

.homeAboutUsTitle {
  margin: 10px 0;
  color: white;
  font-size: 35px;
  font-weight: 500;
  text-align: center;
}

.homeAboutUs2P {
  color: white;
  margin: 0;
}

.homeAboutUsWrapper hr {
  margin: 0;
  margin-top: 30px;
  border-bottom: 2px solid #c2b280;
  width: 140px;
  border-radius: 8px;
}

.homeAboutUsTileContainer {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  margin-top: 70px;
  background-color: #0d0d0d;
}

.homeAboutUsTileContainer div {
  margin: 0px 60px;
  margin-bottom: 50px;
}

/*.homeAboutUsTileContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;
  background-color: #0d0d0d;
}*/

.homeAboutUs1Tile {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 10%;
  margin-top: 100px;
}

.homeAboutUs1Tile h2 {
  margin: 0;
  color: #c2b280;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2px;
}

.homeAboutUs1Tile p {
  margin-top: 100px;
  margin: 0;
  margin-top: 20px;
  margin-left: 60px;
  color: white;
  padding: 0;
}

.homeAboutUs2Tile {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: auto;
  margin-right: 10%;
  margin-top: 100px;
}

.homeAboutUs2Tile h2 {
  margin: 0;
  margin-left: auto;
  color: #c2b280;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2px;
}

.homeAboutUs2Tile p {
  margin: 0;
  margin-top: 20px;
  margin-right: 60px;
  color: white;
}

.homeContactContainer {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
}

.homeContactWrapper {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homeContactWrapper hr {
  margin: 0;
  margin-top: 30px;
  border-bottom: 2px solid #c2b280;
  width: 140px;
  border-radius: 8px;
}

.homeContactTitle {
  margin: 10px 0;
  color: white;
  font-size: 35px;
  font-weight: 500;
  text-align: center;
}

.homeContact1P {
  color: #c2b280;
  margin: 0;
}

.homeContact2P {
  color: white;
  margin: 0;
  text-align: center;
}

.homeContactInfoWrapper {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: 100px;
  margin-bottom: 180px;
  align-items: center;
}

.homeContactEmailWrapper {
  display: flex;
  align-items: center;
}

.homeContactEmailWrapper p {
  color: white;
  margin: 0;
}

.homeContactIcon {
  fill: grey;
  font-size: large;
  cursor: pointer;
  padding-left: 10px;
  transition: 0.2s ease-in-out;
}

.homeContactIcon:hover {
  fill: white;
}

.homeContactInfoWrapper button {
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  margin-top: 30px;
  border: 1px solid #c2b280;
  border-radius: 3px;
  width: 160px;
  height: 50px;
  color: white;
  font-size: 17px;
  font-weight: 300;
  transition: 0.2s ease-in-out;
}

.homeContactInfoWrapper button:hover {
  background-color: #c2b280;
}

.homeLowHr {
  height: 1px;
  background-color: grey;
  border: none;
  margin: 0;
}

.homeCopyrightWrapper {
  display: flex;
  justify-content: center;
}

.homeCopyright {
  margin: 15px 0;
  color: grey;
}

@media screen and (max-width: 1755px) {
  .homeAboutUsContainer {
    margin-top: 320px;
  }
}

@media screen and (max-width: 1386px) {
  .homeAboutUsTileContainer div {
    margin: 0px 30px;
  }
}

@media screen and (max-width: 1266px) {
  .homeCardContainer {
    padding-left: 95px;
  }

  .homeASACard {
    max-height: 300px;
  }
}

@media screen and (max-width: 1207px) {
  /* .homeAboutUsTileContainer div {
    margin: 0px 10px;
  }*/
}

@media screen and (max-width: 1151px) {
  .homeCardContainer {
    width: 100%;
    padding-left: 0px;
    display: flex;
    justify-content: center;
    padding-top: 100px;
  }

  .homeTopContainer {
    flex-direction: column;
  }

  .homeAboutUsContainer {
    margin-top: 200px;
  }

  .homeContactContainer {
    margin-top: 150px;
  }

  .homeAboutUs1Tile {
    width: 80%;
  }

  .homeAboutUs2Tile {
    width: 80%;
  }
}

@media screen and (max-width: 1087px) {
  .homeAboutUsContainer {
    background-color: #0d0d0d;
  }

  .homeAboutUsTileContainer {
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    padding-top: 60px;
  }

  .homeAboutUsTileContainer div {
    margin: 30px 0;
  }
}

@media screen and (max-width: 600px) {
  .homeASACard {
    max-height: 250px;
  }
}

@media screen and (max-width: 560px) {
  .homeTopContainer {
    padding-top: 75px;
  }

  .homeAboutUs1Tile {
    margin-left: 5%;
    width: 90%;
  }

  .homeAboutUs2Tile {
    margin-right: 5%;
    width: 90%;
  }

  .homeAboutUs1Tile p {
    margin-left: 30px;
  }

  .homeAboutUs2Tile p {
    margin-right: 30px;
  }

  .homeASACard {
    max-height: 200px;
  }
}
