.connectWalletContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 666;
  padding: 50px 60px;
  background: #0d0d0d;
  border-radius: 16px;
  border: 1px solid #c2b280;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 7px 3px rgba(194, 178, 128, 0.2);
}

.connectWalletContainer button {
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 10px 0;
  border: 1px solid #c2b280;
  border-radius: 3px;
  width: 200px;
  height: 50px;
  color: white;
  font-size: 17px;
  font-weight: 300;
  transition: 0.2s ease-in-out;
}

.connectWalletContainer button:hover {
  background-color: #c2b280;
}

.connectWalletCloseIcon {
  z-index: 999;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-75%, 75%);
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
  outline: none;
}
