.mintInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 50%;
}

.mintInfoContainer hr {
  margin: 0;
  border-bottom: 2px solid #c2b280;
  width: 140px;
  border-radius: 8px;
}

.mintInfoTitleP {
  color: #c2b280;
  margin: 0;
}

.mintInfoTitle {
  margin: 20px 0;
  color: white;
  font-size: 40px;
  font-weight: 700;
}

.mintInfoDesc {
  color: white;
  margin: 0;
  margin-top: 20px;
}

.mintInfoInfoContainerWrap {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.mintInfoInfoContainer {
  display: flex;
  justify-content: left;
}

.mintInfoInfoWrapper {
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.mintInfoInfoWrapper p {
  color: white;
  margin: 0;
  font-size: 18px;
}

.mintInfoInfoWrapper h3 {
  color: #c2b280;
  margin: 0;
  margin-top: 5px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2px;
}

.mintInfoOpenseaBtn {
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  margin-top: 25px;
  border: 1px solid #c2b280;
  border-radius: 3px;
  width: 200px;
  height: 50px;
  color: white;
  font-size: 17px;
  font-weight: 300;
  transition: 0.2s ease-in-out;
}

.mintInfoOpenseaBtn:hover {
  background-color: #c2b280;
}

@media screen and (max-width: 1151px) {
  .mintInfoContainer {
    width: 100%;
  }
}
