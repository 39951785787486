.sideBarContainer {
  text-align: center;
  justify-content: center;
  position: absolute;
  z-index: 998;
  width: 100%;
  height: 100%;
  background: #212121;
  display: flex;
  flex-direction: column;
  left: 0;
  -webkit-animation: jumpin 0.3s;
  animation: jumpin 0.3s;
  opacity: 100%;
}

@keyframes jumpin {
  from {
    top: -100%;
  }
  to {
    top: 0;
  }
}

.sideBarWrapper {
  text-align: center;
  justify-content: center;
  align-content: center;
  display: grid;
  grid-template-rows: repeat(5, 80px);
  align-items: center;
}

.sideBarWrapper h2 {
  color: white;
  cursor: pointer;
  margin: 0;
  font-size: 20px;
  font-weight: 200;
  position: relative;
}

.sideBarCloseIcon {
  z-index: 999;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%, 75%);
  font-size: 2rem;
  cursor: pointer;
  color: white;
  outline: none;
}

@media screen and (max-width: 1151px) {
  .hamburgerMenu {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 2rem;
    cursor: pointer;
    outline: none;
  }
}

@media screen and (max-width: 560px) {
  .hamburgerMenu {
    transform: translate(-50%, 75%);
  }
}
