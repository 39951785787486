.navBarContainer {
  width: 100%;
  height: 80px;
  background-color: rgba(194, 178, 128, 0.6);
  display: flex;
  justify-content: space-between;
}

.navBarTitleWrapper {
  padding-left: 60px;
  display: flex;
  align-items: center;
}

.navBarTitle {
  color: white;
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 2px;
}

.navBarItemsContainer {
  align-items: center;
  display: flex;
}

.navBarItemsContainer h2 {
  color: white;
  cursor: pointer;
  margin: 0px 20px;
  font-size: 20px;
  font-weight: 200;
  position: relative;
}

.navBarItemsContainer h2:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}

.navBarItemsContainer h2:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.hamburgerMenu {
  display: none;
  color: white;
}

@media screen and (max-width: 1151px) {
  .navBarItemsContainer {
    display: none;
  }
}

@media screen and (max-width: 560px) {
  .navBarTitle {
    letter-spacing: normal;
  }

  .navBarTitleWrapper {
    padding-left: 10px;
  }
}
