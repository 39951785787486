@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  color: white;
  font-family: "Poppins", sans-serif;
}

body {
  /*background-image: url("../public/backgroundImage.jpg");*/
  background-image: url("../public/backgroundImage.jpg");
  background-size: 1920px 2900px;
  background-position-y: -1070px;
  background-repeat: no-repeat;
  background-color: #0d0d0d;
  margin: 0;
  overflow: overflow;
}

::-webkit-scrollbar {
  width: 0px;
  display: none;
}
