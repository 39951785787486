.mintConnectWalletBtn {
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  margin-top: 25px;
  border: 1px solid #c2b280;
  border-radius: 3px;
  width: 200px;
  height: 50px;
  color: white;
  font-size: 17px;
  font-weight: 300;
  transition: 0.2s ease-in-out;
}

.mintConnectWalletBtn:hover {
  background-color: #c2b280;
}

.mintContainer {
  margin-top: 25px;
  display: flex;
}

.mintChangeContainer {
  display: flex;
}

.mintChangeBtn {
  background: none;
  cursor: pointer;
  border: 1px solid #c2b280;
  font-size: 20px;
  padding: 0;
  width: 50px;
  height: 50px;
  transition: 0.2s ease-in-out;
  border-radius: 3px;
}

.mintChangeBtn:hover {
  background-color: #c2b280;
}

.mintAmountWrapper {
  width: 80px;
  display: flex;
  justify-content: center;
}

.mintAmountWrapper h3 {
  margin: 0;
  color: white;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  align-self: center;
}

.mintMintBtn {
  background: none;
  cursor: pointer;
  padding: 0;
  padding: 0;
  margin: 0;
  margin-left: 40px;
  border: 1px solid #c2b280;
  border-radius: 3px;
  height: 50px;
  width: 200px;
  color: white;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 2px;
  transition: 0.2s ease-in-out;
}

.mintMintBtn:hover {
  background-color: #c2b280;
}

@media screen and (max-width: 520px) {
  .mintMintBtn {
    margin-left: 10px;
  }
}

@media screen and (max-width: 480px) {
  .mintContainer {
    flex-direction: column;
  }

  .mintMintBtn {
    margin-left: 0;
    margin-top: 10px;
  }
}
