.aboutUsContainer {
  position: relative;
  width: 300px;
  height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #c2b280;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 0px 7px 3px rgba(194, 178, 128, 0.2);
}

.aboutUsContainer::after {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  content: "";
  background-image: url("../../assets/tileBackground.jpg");
  background-size: 345px 485px;
  border-radius: 20px;
  opacity: 0.2;
}

.aboutUsContainer h2 {
  margin: 0;
  color: white;
  font-size: 25px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 2px;
}

.aboutUsContainer hr {
  margin: 15px 0;
  border-bottom: 2px solid #c2b280;
  width: 140px;
  border-radius: 8px;
}

.aboutUsContainer p {
  margin: 0;
  color: white;
  text-align: center;
}
